<template>
  <div id="app" v-if="appShow">
    <router-view />
  </div>
</template>
<script>
import { doAuth } from "@/assets/js/wechatAuth";
export default {
  created() {
    this.getInfo();
  },
  data() {
    return {
      appShow: false,
      logoShow: false,
    };
  },
  methods: {
    async getInfo() {
      console.log(navigator.userAgent, "navigator.userAgent");
      const authResult = await doAuth();
      console.log(
        "authResult",
        location.href,
        sessionStorage.getItem("activity_id")
      );
      // token
      // const token = decodeURIComponent(
      //   this.$utils.getUrlParam("al") || ""
      // ).replace("1&token=", "");
      // if (token) {
      //   this.$storage.setToken(token);
      // }
      // dc_account_uuid 来自mecard-webview中台免登
      const dc_account_uuid = this.$utils.getUrlParam("dc_account_uuid");
      if (dc_account_uuid) {
        const authLoginResult = await this.$http.get("/wechat/login-by-uuid", {
          dc_account_uuid: dc_account_uuid,
        });
        authLoginResult.code == 200 &&
          authLoginResult.data.token &&
          this.$storage.setToken(authLoginResult.data.token);
      }
      // 判断是否微信小程序
      const isWxMini = await this.$utils.isWxMini();
      isWxMini && sessionStorage.setItem("isWxMini", 1);
      // Mecard-id
      const phoneSecret = this.$utils.getUrlParam("ps");
      if (
        phoneSecret &&
        this.$utils.getUrlParam("al") &&
        !this.$storage.getToken()
      ) {
        const authLoginResult = await this.$http.get("/wechat/auth-login-new", {
          phone_secret: phoneSecret,
        });
        authLoginResult.code == 200 &&
          authLoginResult.data.token &&
          this.$storage.setToken(authLoginResult.data.token);
      }
      // 分享人id
      const share_id = this.$utils.getUrlParam("share_id");
      if (share_id) {
        sessionStorage.setItem("share_id", share_id);
      }
      // token获取userInfo
      if (this.$storage.getToken()) {
        const userInfoResult = await this.$http.get("/user/userinfo");
        if (userInfoResult.code == 200) {
          this.$store.dispatch("saveUserInfo", userInfoResult.data.user_info);
          this.$store.dispatch("saveConfig", userInfoResult.data.config);
          if (userInfoResult.data.user_info["is_black"] == 1) {
            // 封禁用户，禁止进入活动
            this.$toast.fail("账号被封禁，无法登入");
          }
        } else {
          this.$store.dispatch("clearUserInfo");
          this.$store.dispatch("clearConfig", "");
        }
      } else {
        this.$store.dispatch("clearUserInfo");
        this.$store.dispatch("clearConfig", "");
      }
      if (this.$utils.getUrlParam("al")) {
        // history.pushState(
        //   {},
        //   "",
        //   this.$utils.removeURLParameter(location.href, "al") + "&al=1"
        // );
        sessionStorage.setItem("auto-login", 1);
      } else {
        sessionStorage.setItem("auto-login", 0);
      }
      dc_account_uuid &&
        history.pushState(
          {},
          "",
          this.$utils.removeURLParameter(location.href, "dc_account_uuid")
        );
      if (authResult) {
        this.appShow = true;
      } else {
        this.appShow = true;
      }
    },
  },
};
</script>
<style lang="scss">
body,
html,
#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
}
p {
  text-align: justify;
  word-break: break-all;
  margin: 0;
}
.cursor-pointer {
  cursor: pointer;
  user-select: none;
}
.box-shadow {
  box-shadow: 0px 0px 100px 4px #28c8bc7a;
}
.user-tag-auto {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 22px;
  background: rgba(38, 99, 179, 1);
  border-radius: 30px;
  line-height: 0;
  span {
    font-size: 24px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
  }
}
@keyframes backgroundMove {
  0% {
    background-size: 100% 100%;
  }
  50% {
    background-size: 108% 108%;
  }
  100% {
    background-size: 100% 100%;
  }
}
@keyframes big {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.animation-big {
  animation: big 0.25s ease-in-out;
}
</style>
