/* eslint-disable no-undef */
// "pdfjs-dist": "2.5.207",
// const PDFJS = require("pdfjs-dist/es5/build/pdf.js");
// import workerSrc from "pdfjs-dist/es5/build/pdf.worker.entry";
// PDFJS.GlobalWorkerOptions.workerSrc = workerSrc;
/**
 * 是否为微信环境
 */
export const isWechat = () => {
  return !!(
    navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
    "micromessenger"
  );
};
/**
 * 检测微信环境
 */
export const checkWechat = () => {
  return new Promise((resolve, reject) => {
    if (isWechat()) {
      console.log(navigator, "navigator");
      resolve();
    } else {
      reject();
    }
  });
};
/**
 * 获取url后param
 */
export const getUrlParam = (name) => {
  const param = new URLSearchParams(new URL(window.location.href).search).get(
    name
  );
  if (param && param != null) return param;
  return undefined;
};
export const removeURLParameter = (url, parameter) => {
  const urlObject = new URL(url);
  const searchParams = urlObject.searchParams;
  // 移除指定参数
  searchParams.delete(parameter);
  // 获取修改后的 URL
  const modifiedURL =
    urlObject.origin +
    urlObject.pathname +
    "?" +
    searchParams.toString() +
    urlObject.hash;
  return modifiedURL;
};
/**
 * 图片转Base64格式
 */
export const getBase64 = (file) => {
  if (typeof file == "string") {
    //图片url
    return new Promise((resolve, reject) => {
      //一定要设置为let，不然图片不显示
      let image = new Image();

      //解决跨域问题
      image.setAttribute("crossOrigin", "anonymous");
      image.src = file;
      //image.onload为异步加载
      image.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var quality = 0.8;
        //这里的dataurl就是base64类型
        var dataURL = canvas.toDataURL("image/jpeg", quality); //使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
        resolve(dataURL);
      };

      image.onerror = function (error) {
        console.log("Error: ", error);
        reject(error);
      };
    });
  } else {
    //图片文件
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file); // 一定要传入file格式
      reader.onload = () => {
        // console.log("file 转 base64结果：" + reader.result);
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }
};
/**
 * Base64转File格式
 */
export const base64ToFile = (urlData, fileName) => {
  if (typeof urlData !== "string") {
    this.$toast.fail("urlData不是字符串");
    return;
  }
  var arr = urlData.split(",");
  var type = arr[0].match(/:(.*?);/)[1];
  var fileExt = type.split("/")[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName + "." + fileExt, {
    type: type,
  });
};
/**
 * DownloadImage
 */
export const downloadImage = (base64, name) => {
  const fileName = `${name}.jpg`;
  var blob = dataURLtoBlob(base64);
  const elink = document.createElement("a");
  // 设置下载文件名
  elink.download = fileName;
  elink.style.display = "none";
  elink.href = URL.createObjectURL(blob);
  document.body.appendChild(elink);
  elink.click();
  document.body.removeChild(elink);
};
export const downloadImageByUrl = (url) => {
  const elink = document.createElement("a");
  elink.download = url.split("/")[url.split("/").length - 1];
  elink.style.display = "none";
  elink.href = url;
  document.body.appendChild(elink);
  elink.click();
  document.body.removeChild(elink);
};
export const dataURLtoBlob = (dataurl) => {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};
export const isPc = () => {
  return self.document.body.clientWidth >= 914;
};
export const checkWxOrMini = (type) => {
  if (type) {
    // 微信非小程序
    return isMobile().wechat && !sessionStorage.getItem("isWxMini");
  } else {
    // 微信且小程序 或 非微信
    return (
      !isMobile().wechat ||
      (isMobile().wechat && sessionStorage.getItem("isWxMini"))
    );
  }
};
export const isMobile = () => {
  const u = navigator.userAgent;
  return {
    //移动终端浏览器版本信息
    trident: u.indexOf("Trident") > -1, //IE内核
    presto: u.indexOf("Presto") > -1, //opera内核
    webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
    gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
    mac: /macintosh|mac os x/i.test(u) && u.indexOf("iPad") == -1, // 是否mac
    win: /windows|win32/i.test(u), // 是否windows
    mobile:
      !!u.match(/AppleWebKit.*Mobile.*/) || document.body.clientWidth < 914, //是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
    android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
    iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
    iPad: u.indexOf("iPad") > -1, //是否iPad
    webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
    windowsWechat: u.indexOf("WindowsWechat") > -1, // 微信WINDOWS-PC端
    macWechat: u.indexOf("MacWechat") > -1, // 微信MAC-PC端
    wechat:
      !!(u.toLowerCase().match(/MicroMessenger/i) == "micromessenger") &&
      (u.indexOf("Android") > -1 ||
        u.indexOf("iPhone") > -1 ||
        u.indexOf("iPad") > -1), //微信终端
  };
};
export const isWxMini = () => {
  return new Promise((resolve) => {
    if (!isMobile().wechat) {
      resolve(false);
    } else {
      parent.wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    }
  });
};
/**
 * 正则
 */
export const rule = (value, type) => {
  if (type == "nickname") {
    var nicknameReg = /[\u4e00-\u9fa5]/;
    return nicknameReg.test(value);
  } else if (type == "phone") {
    var phoneReg =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    return phoneReg.test(value);
  } else if (type == "wechat") {
    var weChatReg = /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/;
    return weChatReg.test(value);
  } else if (type == "id_card") {
    var id_cardReg =
      /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/;
    return id_cardReg.test(value);
  }
};
export const imgToBase64 = (url, outputFormat) => {
  return new Promise((resolve, reject) => {
    try {
      let canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.crossOrigin = "Anonymous"; //解决Canvas.toDataURL 图片跨域问题
      const canvasUtils = () => {
        let width = img.width;
        let height = img.height;

        // 压缩比例 -- 可以自己修改参数。500px宽度以下原尺寸，大于500px比例处理
        let bili = Math.round(width / 500) || 1;
        let rate = 1 / bili;
        canvas.width = width * rate;
        canvas.height = height * rate;
        ctx.drawImage(
          img,
          0,
          0,
          width,
          height,
          0,
          0,
          width * rate,
          height * rate
        );
        let dataURL = canvas.toDataURL(outputFormat || "image/jpeg");
        // 去除标头 -- 传递给后台时一般去除头部
        // let reg = new RegExp('^data:image/[^;]+;base64,');
        // dataURL = dataURL.replace(reg, '');
        canvas = null;
        return dataURL;
      };
      img.onload = () => {
        resolve(canvasUtils());
      };
      setTimeout(() => {
        resolve(canvasUtils());
      }, 3000);
      img.src = url;
    } catch (err) {
      reject(err);
    }
  });
};
export const windowResizeByPortrait = () => {
  const height = document.body.clientHeight,
    width = document.body.clientWidth;
  if (document.body.clientHeight > document.body.clientWidth) {
    // 竖屏
    return {
      width: `${height}px`,
      height: `${width}px`,
      top: `${(height - width) / 2}px`,
      left: `${0 - (height - width) / 2}px`,
    };
  } else {
    // 横屏
    return {
      width: `${width}px`,
      height: `${height}px`,
      top: `0`,
      left: `0`,
    };
  }
};

export const getDayByDate = (str) => {
  const weeks = [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ];
  return weeks[new Date(str).getDay()];
};

export const timestampToTime = (timestamp) => {
  const date = new Date(timestamp); //毫秒
  const Y = date.getFullYear() + "-";
  const M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  const D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  const h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  const m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  const s =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
};

export const timeItemFormat = (v) => {
  return v < 10 ? "0" + v : v;
};

export const fullscreen = (element) => {
  try {
    if (!element) {
      element = document.documentElement;
    }
    if (element.requestFullscreen) {
      element.requestFullscreen().then(
        () => {},
        () => {}
      );
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    }
  } catch (e) {
    //
  }
};

export const isFullscreen = () => {
  const clientHeight =
    document.documentElement.clientHeight || document.body.clientHeight;
  return screen.height == clientHeight;
};

export const exitFullscreen = (element) => {
  if (!element) {
    element = document.documentElement;
  }
  try {
    if (element.exitFullscreen) {
      element.exitFullscreen();
    } else if (element.mozCancelFullScreen) {
      element.mozCancelFullScreen();
    } else if (element.webkitExitFullscreen) {
      element.webkitExitFullscreen();
    }
  } catch (e) {
    //
  }
};

export const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return `data:image/jpeg;base64,${window.btoa(binary)}`;
};

export const getFirstFrameByVideo = (video) => {
  return new Promise((resolve) => {
    let canvasElem = document.createElement("canvas");
    let videoElem = document.createElement("video");
    videoElem.autoplay = true;
    videoElem.muted = true;
    videoElem.preload = true;
    videoElem.setAttribute("crossOrigin", "anonymous");
    videoElem.src = video;
    videoElem.addEventListener(
      "loadeddata",
      () => {
        let { videoWidth, videoHeight } = videoElem;
        canvasElem.width = videoWidth;
        canvasElem.height = videoHeight;
        canvasElem
          .getContext("2d")
          .drawImage(videoElem, 0, 0, videoWidth, videoHeight);
        resolve({
          video: video,
          poster: canvasElem.toDataURL("image/jpeg"),
        });
        videoElem.src = "";
      },
      false
    );
  });
};
export const getVideoMpegStream = ({ elem, url, screenItem }) => {
  return new Promise((resolve, reject) => {
    let videoInstance;
    try {
      if (!elem) {
        elem = document.createElement("video");
      }
      console.log(elem, "elem");
      elem.width = screenItem?.width || 1920;
      elem.height = screenItem?.height || 1080;
      elem.setAttribute("crossOrigin", "anonymous");
      videoInstance = videojs(elem.getAttribute("id"), {
        controls: false,
        autoplay: true,
        loop: true,
        preload: "auto",
        sources: [
          {
            type: "application/x-mpegURL",
            src: url, //视频播放地址
          },
        ],
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        html5: {
          hls: {
            overrideNative: false,
          },
          nativeVideoTracks: true,
          nativeAudioTracks: true,
          nativeTextTracks: true,
        },
      });
      let already = false;
      videoInstance.on("loadeddata", function () {
        if (!already) {
          already = true;
          resolve({
            elem: elem,
            url: url,
            instance: videoInstance,
            stream: elem.mozCaptureStream
              ? elem.mozCaptureStream()
              : elem.captureStream(),
          });
        }
      });
      videoInstance.on("loadedmetadata", function () {
        if (!already) {
          already = true;
          resolve({
            elem: elem,
            url: url,
            instance: videoInstance,
            stream: elem.mozCaptureStream
              ? elem.mozCaptureStream()
              : elem.captureStream(),
          });
        }
      });
      videoInstance.on("error", function (e) {
        try {
          videoInstance && videoInstance.dispose();
          if (
            !document.getElementById(
              `prepare_video_mpeg_${screenItem.screen_u3d}`
            )
          ) {
            const elem = document.createElement("video");
            elem.id = `prepare_video_mpeg_${screenItem.screen_u3d}`;
            elem.className = "prepare-div";
            elem.setAttribute("playsinline", "playsinline");
            elem.setAttribute("x5-playsinline", "x5-playsinline");
            elem.setAttribute("webkit-playsinline", "webkit-playsinline");
            document.getElementById("gameIndex").appendChild(elem);
          }
        } catch (error) {
          console.log(error, "dispose");
        }
        reject(e);
      });
      videoInstance.play();
    } catch (e) {
      console.log(e, "e222");
      try {
        videoInstance && videoInstance.dispose();
        if (
          !document.getElementById(
            `prepare_video_mpeg_${screenItem.screen_u3d}`
          )
        ) {
          const elem = document.createElement("video");
          elem.id = `prepare_video_mpeg_${screenItem.screen_u3d}`;
          elem.className = "prepare-div";
          elem.setAttribute("playsinline", "playsinline");
          elem.setAttribute("x5-playsinline", "x5-playsinline");
          elem.setAttribute("webkit-playsinline", "webkit-playsinline");
          document.getElementById("gameIndex").appendChild(elem);
        }
      } catch (error) {
        console.log(error, "dispose");
      }
      reject(e);
    }
  });
};
export const getVideoFlvStream = ({ elem, url, screenItem }) => {
  return new Promise((resolve, reject) => {
    let videoInstance;
    try {
      if (!elem) {
        elem = document.createElement("video");
      }
      console.log(elem, "elem");
      elem.width = screenItem?.width || 1920;
      elem.height = screenItem?.height || 1080;
      elem.setAttribute("crossOrigin", "anonymous");
      if (flvjs.isSupported()) {
        videoInstance = flvjs.createPlayer({
          type: "flv",
          url,
        });
        console.log(videoInstance, "videoPlayer");
        videoInstance.attachMediaElement(elem);
      } else {
        reject("不支持flv");
      }
      let already = false;
      videoInstance.on(flvjs.Events.LOADING_COMPLETE, function (e) {
        console.log(e, "LOADING_COMPLETE");
        if (!already) {
          already = true;
          resolve({
            elem: elem,
            url: url,
            instance: videoInstance,
            stream: elem.mozCaptureStream
              ? elem.mozCaptureStream()
              : elem.captureStream(),
          });
        }
      });
      videoInstance.on(flvjs.Events.ERROR, function (e) {
        console.log(e, "ERROR");
        try {
          videoInstance && videoInstance.destroy();
          if (
            !document.getElementById(
              `prepare_video_mpeg_${screenItem.screen_u3d}`
            )
          ) {
            const elem = document.createElement("video");
            elem.id = `prepare_video_mpeg_${screenItem.screen_u3d}`;
            elem.className = "prepare-div";
            elem.setAttribute("playsinline", "playsinline");
            elem.setAttribute("x5-playsinline", "x5-playsinline");
            elem.setAttribute("webkit-playsinline", "webkit-playsinline");
            document.getElementById("gameIndex").appendChild(elem);
          }
        } catch (error) {
          console.log(error, "dispose");
        }
        reject(e);
      });
      videoInstance.load();
      videoInstance.play();
      setTimeout(() => {
        resolve({
          elem: elem,
          url: url,
          instance: videoInstance,
          stream: elem.mozCaptureStream
            ? elem.mozCaptureStream()
            : elem.captureStream(),
        });
      }, 1500);
    } catch (e) {
      console.log(e, "e222");
      try {
        videoInstance && videoInstance.destroy();
        if (
          !document.getElementById(
            `prepare_video_mpeg_${screenItem.screen_u3d}`
          )
        ) {
          const elem = document.createElement("video");
          elem.id = `prepare_video_mpeg_${screenItem.screen_u3d}`;
          elem.className = "prepare-div";
          elem.setAttribute("playsinline", "playsinline");
          elem.setAttribute("x5-playsinline", "x5-playsinline");
          elem.setAttribute("webkit-playsinline", "webkit-playsinline");
          document.getElementById("gameIndex").appendChild(elem);
        }
      } catch (error) {
        console.log(error, "dispose");
      }
      reject(e);
    }
  });
};
export const getVideoStream = ({ elem, url, screenItem }) => {
  return new Promise((resolve) => {
    if (!elem) {
      elem = document.createElement("video");
    }
    console.log(elem, "elem");
    elem.width = screenItem?.width || 1920;
    elem.height = screenItem?.height || 1080;
    elem.autoplay = true;
    // elem.muted = true;
    elem.loop = true;
    elem.preload = true;
    elem.setAttribute("crossOrigin", "anonymous");
    elem.src = url;
    let already = false;
    elem.addEventListener("loadeddata", () => {
      if (!already) {
        already = true;
        resolve({
          elem: elem,
          url: url,
          stream: elem.mozCaptureStream
            ? elem.mozCaptureStream()
            : elem.captureStream(),
        });
      }
    });
    elem.addEventListener("loadedmetadata", () => {
      if (!already) {
        already = true;
        resolve({
          elem: elem,
          url: url,
          stream: elem.mozCaptureStream
            ? elem.mozCaptureStream()
            : elem.captureStream(),
        });
      }
    });
  });
};
export const getCanvasStream = ({
  elem,
  url,
  callback,
  check,
  fillColor,
  screenItem,
}) => {
  return new Promise((resolve) => {
    if (!elem || Object.keys(elem).length == 0) {
      elem = document.createElement("canvas");
    }
    console.log(elem, "elem");
    const ctx = elem.getContext("2d");
    const img = new Image();
    img.crossOrigin = "Anonymous"; //elem.toDataURL 图片跨域问题
    let already = false;
    const canvasUtils = () => {
      let width = img.width;
      let height = img.height;
      const canvasWidth = screenItem?.width || 1920;
      const canvasHeight = screenItem?.height || 1080;
      let rate = 1,
        dx = 0,
        dy = 0;
      elem.width = canvasWidth * rate;
      elem.height = canvasHeight * rate;
      elem.style.width = canvasWidth + "px";
      elem.style.height = canvasHeight + "px";
      if (width / height > canvasWidth / canvasHeight) {
        height = (height / width) * canvasWidth;
        width = canvasWidth;
        dy = (canvasHeight - height) / 2;
      } else {
        width = (width / height) * canvasHeight;
        height = canvasHeight;
        dx = (canvasWidth - width) / 2;
      }
      ctx.clearRect(0, 0, elem.width, elem.height);
      if (fillColor) {
        ctx.fillStyle = fillColor;
        ctx.fillRect(dx, dy, width, height);
      }
      ctx.drawImage(img, dx, dy, width, height);
      elem.setAttribute("data-dx", dx);
      elem.setAttribute("data-dy", dy);
      elem.setAttribute("data-width", width);
      elem.setAttribute("data-height", height);
      if (
        !(
          elem.getAttribute("data-interval") &&
          Number(elem.getAttribute("data-interval")) > 0
        ) &&
        !check
      ) {
        elem.setAttribute("data-interval", 1);
        elem.setAttribute("data-interval-stop", 0);
        let timer = setInterval(function fn() {
          console.log(11111);
          if (
            elem &&
            ctx &&
            !(
              elem.getAttribute("data-interval-stop") &&
              Number(elem.getAttribute("data-interval-stop")) > 0
            )
          ) {
            ctx.strokeRect(0, 0, 1, 1);
          } else {
            clearInterval(timer);
            elem.setAttribute("data-interval", 0);
            elem.setAttribute("data-interval-stop", 0);
          }
        }, 1000);
      }
      // store.dispatch("saveCanvasTimers", obj);
      const result = {
        img: img,
        elem: elem,
        stream: elem.mozCaptureStream
          ? elem.mozCaptureStream()
          : elem.captureStream(),
      };
      callback && callback(result);
      return result;
    };
    img.onload = () => {
      if (!already) {
        already = true;
        resolve(canvasUtils());
      }
    };
    setTimeout(() => {
      if (!already) {
        already = true;
        resolve(canvasUtils());
      }
    }, 5000);
    img.src = url;
  });
};
export const getCanvasStreamFromPDFAction = async ({
  elem = {},
  url,
  callback,
  page,
  controlStream,
  check,
  screenItem,
}) => {
  console.log(url, "url");
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = async function () {
    if (this.status == 200) {
      var blob = this.response;
      var blobUrl = URL.createObjectURL(blob);
      const pdfDocument = await PDFJS.getDocument(blobUrl).promise;
      console.log(pdfDocument, "pdfDocument");
      const pdfPage = await pdfDocument.getPage(page);
      console.log(pdfPage, "pdfPage");
      const pdfCanvas = document.createElement("canvas");
      const ctx = pdfCanvas.getContext("2d");
      let ratio = window.devicePixelRatio || 1;
      let viewport = pdfPage.getViewport({
        scale: screen.availWidth / pdfPage.getViewport({ scale: 1 }).width,
      });
      console.log(viewport, "viewport");
      pdfCanvas.width = viewport.width * ratio;
      pdfCanvas.height = viewport.height * ratio;
      pdfCanvas.style.width = viewport.width + "px";
      pdfCanvas.style.height = viewport.height + "px";
      ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
      let renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };
      let error = false;
      const timeStart = Date.now();
      try {
        await pdfPage.render(renderContext).promise;
      } catch (e) {
        error = e;
      }
      const timeEnd = Date.now();
      if (check && (timeEnd - timeStart > 10000 || error)) {
        callback({
          error,
        });
        return;
      }
      const base64 = pdfCanvas.toDataURL();
      if (controlStream) {
        callback({
          url: base64,
        });
      } else {
        getCanvasStream({
          elem,
          url: base64,
          check,
          screenItem,
          callback: (result) => {
            callback({
              ...result,
              pdfNumPages: pdfDocument.numPages,
            });
          },
        });
      }
    }
  };
  xhr.send();
};
export const getCanvasStreamFromPDF = ({
  elem,
  url,
  page = 1,
  controlStream,
  check,
}) => {
  return new Promise((resolve, reject) => {
    getCanvasStreamFromPDFAction({
      elem,
      url,
      callback: (res) => {
        if (res.error) {
          reject(res.error);
        } else {
          resolve(res);
        }
      },
      page,
      controlStream,
      check,
    });
  });
};
export const videoFormatTime = (t) => {
  let h = parseInt(t / 3600);
  h = h < 10 ? "0" + h : h;
  let m = parseInt((t % 3600) / 60);
  m = m < 10 ? "0" + m : m;
  let s = parseInt(t % 60);
  s = s < 10 ? "0" + s : s;
  if (h == "00") {
    return m + ":" + s;
  } else {
    return h + ":" + m + ":" + s;
  }
};
export const changeURLArg = (url, arg, arg_val) => {
  var pattern = arg + "=([^&]*)";
  var replaceText = arg + "=" + arg_val;
  if (url.match(pattern)) {
    var tmp = "/(" + arg + "=)([^&]*)/gi";
    tmp = url.replace(eval(tmp), replaceText);
    return tmp;
  } else {
    if (url.match("[?]")) {
      return url + "&" + replaceText;
    } else {
      return url + "?" + replaceText;
    }
  }
};

export const windowOpen = (url) => {
  if (isMobile().mobile) {
    location.href = url;
  } else {
    window.open(url);
  }
};

export const getURLArg = (url, key) => {
  let arr = url.split("?");
  let argStr = arr.length > 1 ? arr[1] : "";
  if (!argStr) {
    return "";
  }
  let arr2 = argStr.split("&").map((item) => item.split("="));
  let res = arr2.find((item) => item[0] == key);
  if (res) {
    return res.length > 1 ? res[1] : "";
  } else {
    return "";
  }
};

export const getMusicDuration = (url) => {
  return new Promise((resolve) => {
    const audio = new Audio();
    audio.src = url;
    audio.onloadedmetadata = () => {
      resolve(audio.duration);
    };
  });
};

export const formatDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = Math.floor(duration - hours * 3600 - minutes * 60);

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  if (hours > 0) {
    return `${hours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
};

export const fileNameShow = (v) => {
  v = v.substring(v.lastIndexOf("/") + 1);
  v = v.substring(v.indexOf("-") + 1);
  return decodeURIComponent(v);
};

export const getFileExtension = (url) => {
  // 获取最后一个"."的索引
  var dotIndex = url.lastIndexOf(".");
  // 如果找不到"."或者"."在末尾，则返回空字符串
  if (dotIndex === -1 || dotIndex === url.length - 1) {
    return "";
  }
  // 使用slice方法获取从最后一个"."之后的字符串，并转换为小写
  var extension = url.slice(dotIndex + 1).toLowerCase();
  return extension;
};

/**
 * 深拷贝
 * @param {*} obj 拷贝对象(object or array)
 * @param {*} cache 缓存数组
 */
export const deepClone = function (obj, cache = []) {
  // typeof [] => 'object'
  // typeof {} => 'object'
  if (obj === null || typeof obj !== "object") {
    return obj;
  }
  // 如果传入的对象与缓存的相等, 则递归结束, 这样防止循环
  /**
   * 类似下面这种
   * var a = {b:1}
   * a.c = a
   * 资料: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
   */
  const hit = cache.filter((c) => c.original === obj)[0];
  if (hit) {
    return hit.copy;
  }

  const copy = Array.isArray(obj) ? [] : {};
  // 将copy首先放入cache, 因为我们需要在递归deepCopy的时候引用它
  cache.push({
    original: obj,
    copy,
  });
  Object.keys(obj).forEach((key) => {
    copy[key] = deepClone(obj[key], cache);
  });

  return copy;
};
