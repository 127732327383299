import { get } from "./http";
export const TOKEN_NAME = "token";
export const LOGOUT_EXIST = "logout_exist";
/**
 * 用户信息
 */
export const USERINFO = {
  user_name: "user_name",
  user_id: "user_id",
  role_id: "role_id",
  sex: "sex",
  avatar: "avatar",
  user_type: "user_type",
};
/**
 * 服务器
 */
export const CONFIG = {
  ip: "ip",
  port: "port",
  room_id: "room_id",
  user_max_limit: "user_max_limit",
  screen_name: "screen_name",
  screen_type: "screen_type",
  language: "language",
};
/**
 * 存储token
 */
export const setToken = (token) => {
  if (token) {
    localStorage.setItem(TOKEN_NAME, token);
  }
};
/**
 * 清除token
 */
export const clearToken = () => {
  localStorage.setItem(TOKEN_NAME, "");
  sessionStorage.setItem(LOGOUT_EXIST, 1);
};
/**
 * 获取token
 */
export const getToken = () => {
  return localStorage.getItem(TOKEN_NAME);
};
export const getLogoutExist = () => {
  return sessionStorage.getItem(LOGOUT_EXIST);
};
/**
 * 存储用户信息
 */
export const setUserInfo = (info) => {
  Object.keys(info).forEach((key) => {
    localStorage.setItem(key, info[key]);
  });
};
/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  const userInfo = {};
  Object.keys(USERINFO).forEach((key) => {
    userInfo[USERINFO[key]] = localStorage.getItem(USERINFO[key]);
  });
  return userInfo;
};
/**
 * 存储服务器信息
 */
export const setConfig = (info) => {
  Object.keys(info).forEach((key) => {
    localStorage.setItem(key, info[key]);
  });
};
/**
 * 获取服务器信息
 */
export const getConfig = () => {
  const config = {};
  Object.keys(CONFIG).forEach((key) => {
    config[CONFIG[key]] = localStorage.getItem(CONFIG[key]);
  });
  return config;
};
/**
 * 更新用户信息
 */
export const updateUserInfo = () => {
  return new Promise((resolve) => {
    get("/user/get-info").then((res) => {
      if (res.code === 200 && res.data) {
        // console.log(res.data.classes, res.data.classes.class_img);
        const userInfo = {};
        userInfo[USERINFO.ID] = res.data.id || "";
        userInfo[USERINFO.NAME] = res.data.nickname || "";
        userInfo[USERINFO.AVATAR] = res.data.avatar || "";
        userInfo[USERINFO.PHONE] = res.data.phone || "";
        userInfo[USERINFO.WECHAT] = res.data.wechat || "";
        setUserInfo(userInfo);
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
};
/**
 * 校验token
 */
export const checkToken = () => {
  return new Promise((resolve) => {
    const token = getToken();
    if (token) {
      get("/wechat/check-token").then((res) => {
        if (res.code === 200 && res.data) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    } else {
      resolve(false);
    }
  });
};
/**
 * 业务层-校验登录
 */
export const checkLogin = () => {
  return new Promise((resolve) => {
    resolve(!!getToken());
  });
};
/**
 * 中英字典字段
 */
export const LANGUAGE_LOCALES = "languageLocales";
/**
 * 存取MeCard Url Scheme
 */
const MECARD_NAME = "mecardUrl";
export const getMcUrl = () => {
  return localStorage.getItem(MECARD_NAME);
};
export const setMcUrl = () => {
  return new Promise((resolve) => {
    get("/wechat/generatescheme").then((res) => {
      if (res.code === 200 && res.data) {
        res.data.openLink &&
          localStorage.setItem(MECARD_NAME, res.data.openLink);
        resolve();
      }
    });
  });
};
