import { isMobile, isPc } from "../../assets/js/utils";
import * as http from "../../assets/js/http";
// 添加私聊聊天记录
export const addMessage = (state, { messages }) => {
  // let user = {};
  // if (messages[0].from_user.user_id != state.userInfo.user_id) {
  //   user = messages[0].from_user;
  // }
  // if (messages[0].to_user.user_id != state.userInfo.user_id) {
  //   user = messages[0].to_user;
  // }
  // console.log(user, "user");
  // if (
  //   !(state.messages[user.user_id] && state.messages[user.user_id].length > 0)
  // ) {
  //   state.messages[user.user_id] = [];
  // }
  // state.messages[user.user_id] = [...state.messages[user.user_id], ...messages];
  // console.log(state.messages, "state.messages");
  state.messages = [...state.messages, ...messages];
};

// 添加公共聊天记录
export const addMessageAll = (state, { messages }) => {
  state.messagesAll = [...state.messagesAll, ...messages];
};

// 保存用户信息
export const saveUserInfo = (state, userInfo) => {
  // console.log(userInfo, "userInfo");
  const info = {};
  info.user_name = userInfo.user_name || "";
  info.user_id = userInfo.user_id || "";
  info.role_id = userInfo.role_id || "";
  info.sex = userInfo.sex || 0;
  info.avatar = userInfo.avatar || "";
  info.curr_scene_id = userInfo.curr_scene_id || "";
  info.curr_scene_index = userInfo.curr_scene_index || 0;
  info.user_tag = userInfo.user_tag || "";
  info.is_audio = userInfo.is_audio || 0;
  info.is_video = userInfo.is_video || 0;
  info.is_black = userInfo.is_black || 0;
  info.is_move = userInfo.is_move || 0;
  info.phone = userInfo.phone || "";
  info.small_img = userInfo.small_img || "";
  info.web_data = userInfo.web_data || "";
  info.openid = userInfo.openid || "";
  state.userInfo = info;
};

// 保存服务器配置
export const saveConfig = (state, config) => {
  config.scenes &&
    config.scenes.map((i, k) => {
      if (!i.channel) {
        if (i.scene_id === state.userInfo.curr_scene_id) {
          state.userInfo.curr_scene_index = k;
          if (i.user_type == 1) {
            state.userInfo.is_audio = 1;
            state.userInfo.is_video = 1;
            state.userInfo.is_move = 1;
          }
        }
        i.channel = `scene_${sessionStorage.getItem("activity_id") || 0}_${
          config.room_id
        }_${i.scene_id}`;
      }
    });
  // 数字型，0：其他，1：微信小程序，2：PC，3：移动端
  config["environment"] = sessionStorage.getItem("isWxMini")
    ? 1
    : isMobile().mobile
    ? 3
    : isPc()
    ? 2
    : 0;
  // 数字型，0：其他，1：Windows，2：Mac，3：Android，4：IPhone，5：IPad
  config["system"] = isMobile().android
    ? 3
    : isMobile().iPhone
    ? 4
    : isMobile().iPad
    ? 5
    : isMobile().mac
    ? 2
    : isMobile().win
    ? 1
    : 0;
  config["app_version"] = navigator.userAgent;
  state.config = config;
  console.log(config, "saveConfig");
};

// 保存活动详情
export const saveDetail = (state, detail) => {
  state.detail = detail;
};

// 清空用户信息
export const clearUserInfo = (state) => {
  state.userInfo = "";
};

// 清空服务器配置
export const clearConfig = (state) => {
  state.config = "";
};

// 清空活动详情
export const clearDetail = (state) => {
  state.detail = "";
};

// 添加文字聊天左侧用户列表
export const addTextChatUserList = (state, user) => {
  if (state.textChatUserList && state.textChatUserList.length > 0) {
    let alreadyExist = false;
    state.textChatUserList.map((i) => {
      if (i.user_id == user.user_id) {
        alreadyExist = true;
      }
    });
    if (!alreadyExist) {
      user["count"] = 0;
      state.textChatUserList.push(user);
    }
  } else {
    user["count"] = 0;
    state.textChatUserList.push(user);
  }
};

// 保存文字聊天左侧用户列表
export const saveTextChatUserList = (state, TextChatUserList) => {
  state.TextChatUserList = TextChatUserList;
};

// 添加发言申请用户列表
export const addTalkAuthApplyList = (state, user) => {
  state.talkAuthApplyList = [user, ...state.talkAuthApplyList];
};

// 保存发言申请用户列表
export const saveTalkAuthApplyList = (state, talkAuthApplyList) => {
  state.talkAuthApplyList = talkAuthApplyList;
};

// 取消发言申请用户列表
export const cancelTalkAuthApplyList = (state, user) => {
  const talkAuthApplyList = state.talkAuthApplyList;
  talkAuthApplyList.map((item) => {
    if (item.user_id == user.user_id) {
      item.submit_status = -1;
    }
  });
  state.talkAuthApplyList = talkAuthApplyList;
};

// 保存通信对象
export const saveAgoraRtc = (state, agoraRtc) => {
  state.agoraRtc = agoraRtc;
};

// 保存直播对象
export const saveAgoraLive = (state, agoraLive) => {
  state.agoraLive = agoraLive;
};

// 添加直播对象组-给更多屏幕
export const addAgoraLiveMoreList = (state, agoraLiveMoreList) => {
  state.agoraLiveMoreList = [...state.agoraLiveMoreList, ...agoraLiveMoreList];
};

// 保存直播对象组-给更多屏幕
export const saveAgoraLiveMoreList = (state, agoraLiveMoreList) => {
  state.agoraLiveMoreList = agoraLiveMoreList;
};

// 添加直播对象-屏幕id绑定组
export const addAgoraLiveMoreScreenBindList = (
  state,
  agoraLiveMoreScreenBindList
) => {
  state.agoraLiveMoreScreenBindList = [
    ...state.agoraLiveMoreScreenBindList,
    ...agoraLiveMoreScreenBindList,
  ];
};

// 保存直播对象-屏幕id绑定组
export const saveAgoraLiveMoreScreenBindList = (
  state,
  agoraLiveMoreScreenBindList
) => {
  state.agoraLiveMoreScreenBindList = agoraLiveMoreScreenBindList;
};

// 添加视频聊天用户列表
export const addVideoChatUserList = (state, videoChatUserList) => {
  state.videoChatUserList = [...state.videoChatUserList, ...videoChatUserList];
};

// 保存视频聊天用户列表
export const saveVideoChatUserList = (state, videoChatUserList) => {
  state.videoChatUserList = videoChatUserList;
};

// 添加正在投屏列表
export const addDoingVideoProjectList = (state, doingVideoProjectList) => {
  state.doingVideoProjectList = [
    ...state.doingVideoProjectList,
    ...doingVideoProjectList,
  ];
};

// 保存正在投屏列表
export const saveDoingVideoProjectList = (state, doingVideoProjectList) => {
  state.doingVideoProjectList = doingVideoProjectList;
};

// 保存推流监听-共享屏幕列表
export const saveAgoraLiveUserPublishedListenerMoreScreenBindList = (
  state,
  agoraLiveUserPublishedListenerMoreScreenBindList
) => {
  state.agoraLiveUserPublishedListenerMoreScreenBindList =
    agoraLiveUserPublishedListenerMoreScreenBindList;
};

// 保存画布视频dom列表
export const savePrepareList = (state, prepareList) => {
  state.prepareList = prepareList;
};

// 保存直播监听
export const saveAgoraLiveListener = (state, agoraLiveListener) => {
  state.agoraLiveListener = agoraLiveListener;
};

// 保存画布定时器集合
export const saveCanvasTimers = (state, canvasTimers) => {
  state.canvasTimers = canvasTimers;
};

// 保存声音控制
export const saveSoundControl = (state, soundControl) => {
  state.soundControl = soundControl;
};

// 保存在线用户列表
export const saveOnlineUserList = (state, onlineUserList) => {
  state.onlineUserList = onlineUserList;
};

// 保存客服二维码
export const saveCsQrcode = async (state, { notActivityId, callback }) => {
  notActivityId && sessionStorage.setItem("activity_id", "");
  const { code, data } = await http.post("/kefu/contact");
  code == 200 &&
    data &&
    (state.csQrcode = {
      url: data.qrcode,
      type: data.is_default, // 1：代表平台默认二维码，0：代表活动设置的二维码
    }) &&
    callback &&
    callback();
};

// 添加授权发言来源记录列表
export const addAuthAudioMark = (state, { user_id, authAudioMark }) => {
  state.authAudioMark[user_id] = authAudioMark;
};
