import Vue from "vue";
import {
  Area,
  Calendar,
  Popup,
  Toast,
  Uploader,
  Popover,
  Grid,
  GridItem,
  Icon,
  ImagePreview,
  Dialog,
  DatetimePicker,
  Lazyload,
  ShareSheet,
  Picker,
  Loading,
  Swipe,
  SwipeItem,
  Checkbox,
  CheckboxGroup,
  Form,
  Field,
  Button,
  Empty,
  List,
  Switch,
  Pagination 
} from "vant";
import "vant/lib/index.css";
import "@vant/touch-emulator";

Vue.use(Area)
  .use(Calendar)
  .use(Popup)
  .use(Uploader)
  .use(Popover)
  .use(Grid)
  .use(GridItem)
  .use(Icon)
  .use(Dialog)
  .use(DatetimePicker)
  .use(ImagePreview)
  .use(Toast)
  .use(Lazyload)
  .use(ShareSheet)
  .use(Picker)
  .use(Loading)
  .use(Swipe)
  .use(Switch)
  .use(SwipeItem)
  .use(Checkbox)
  .use(Empty)
  .use(List)
  .use(CheckboxGroup)
  .use(Form)
  .use(Button)
  .use(Pagination)
  .use(Field);
