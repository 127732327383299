import * as http from "../http";
import { VideoPlayer } from "./videoplayer.js";
import { RenderStreaming } from "./renderstreaming.js";
import { Signaling, WebSocketSignaling } from "./signaling.js";
export async function setup({
  ip,
  elem,
  receiveHandler,
  connectHandler,
  disconnectHandler,
  _this,
}) {
  const videoPlayer = new VideoPlayer();
  videoPlayer.createPlayer(elem);

  const { useWebSocket } = await getServerConfig(ip);
  const signaling = useWebSocket
    ? new WebSocketSignaling(cleanIp(ip))
    : new Signaling(cleanIp("http://" + ip));
  const config = getRTCConfiguration();

  let renderstreaming = new RenderStreaming(signaling, config);

  renderstreaming.onConnect = () => {
    console.log(11111, "renderstreaming.onConnect");
    const channel = renderstreaming.createDataChannel("input");
    console.log(channel, "channel");
    videoPlayer.setupInput({
      channel,
      receiveHandler,
      connectHandler,
    });
  };

  renderstreaming.onDisconnect = async () => {
    console.log(22222);
    await renderstreaming.stop();
    renderstreaming = null;
    videoPlayer.deletePlayer();
    disconnectHandler && disconnectHandler();
  };
  renderstreaming.onTrackEvent = (data) => videoPlayer.addTrack(data.track);
  await renderstreaming.start();
  await renderstreaming.createConnection(_this?.$route?.query?.cid || "");

  window.addEventListener(
    "resize",
    function () {
      videoPlayer.resizeVideo();
    },
    true
  );

  window.addEventListener(
    "beforeunload",
    async () => {
      if (!renderstreaming) return;
      await renderstreaming.stop();
    },
    true
  );

  _this.$webrtcPlayer = videoPlayer;
}

function cleanIp(v) {
  return v
    .replace(`${process.env.VUE_APP_EXTERNAL_LINK}`, "")
    .replace(`http://`, "");
}

function getServerConfig(ip) {
  return http.get(ip + "/config");
}

function getRTCConfiguration() {
  let config = {};
  config.sdpSemantics = "unified-plan";
  config.iceServers = [
    {
      urls: ["stun:43.136.92.231:3478"],
    },
    {
      urls: ["turn:43.136.92.231:3478"],
      username: "sct",
      credential: "832545",
    },
  ];
  return config;
}
