export const Keymap = {
  Space: 1,
  Enter: 2,
  Tab: 3,
  Backquote: 4,
  Quote: 5,
  Semicolon: 6,
  Comma: 7,
  Period: 8,
  Slash: 9,
  Backslash: 10,
  BracketLeft: 11,
  BracketRight: 12,
  Minus: 13,
  Equal: 14,
  KeyA: 15,
  KeyB: 16,
  KeyC: 17,
  KeyD: 18,
  KeyE: 19,
  KeyF: 20,
  KeyG: 21,
  KeyH: 22,
  KeyI: 23,
  KeyJ: 24,
  KeyK: 25,
  KeyL: 26,
  KeyM: 27,
  KeyN: 28,
  KeyO: 29,
  KeyP: 30,
  KeyQ: 31,
  KeyR: 32,
  KeyS: 33,
  KeyT: 34,
  KeyU: 35,
  KeyV: 36,
  KeyW: 37,
  KeyX: 38,
  KeyY: 39,
  KeyZ: 40,
  Digit1: 41,
  Digit2: 42,
  Digit3: 43,
  Digit4: 44,
  Digit5: 45,
  Digit6: 46,
  Digit7: 47,
  Digit8: 48,
  Digit9: 49,
  Digit0: 50,
  ShiftLeft: 51,
  ShiftRight: 52,
  AltLeft: 53,
  AltRight: 54,
  // "AltGr": 54,
  ControlLeft: 55,
  ControlRight: 56,
  MetaLeft: 57,
  MetaRight: 58,
  // "LeftWindows": 57,
  // "RightWindows": 58,
  // "LeftApple": 57,
  // "RightApple": 58,
  // "LeftCommand": 57,
  // "RightCommand": 58,
  ContextMenu: 59,
  Escape: 60,
  ArrowLeft: 61,
  ArrowRight: 62,
  ArrowUp: 63,
  ArrowDown: 64,
  Backspace: 65,
  PageDown: 66,
  PageUp: 67,
  Home: 68,
  End: 69,
  Insert: 70,
  Delete: 71,
  CapsLock: 72,
  NumLock: 73,
  PrintScreen: 74,
  ScrollLock: 75,
  Pause: 76,
  NumpadEnter: 77,
  NumpadDivide: 78,
  NumpadMultiply: 79,
  NumpadAdd: 80,
  NumpadSubtract: 81,
  NumpadDecimal: 82,
  NumpadEquals: 83,
  Numpad0: 84,
  Numpad1: 85,
  Numpad2: 86,
  Numpad3: 87,
  Numpad4: 88,
  Numpad5: 89,
  Numpad6: 90,
  Numpad7: 91,
  Numpad8: 92,
  Numpad9: 93,
  F1: 94,
  F2: 95,
  F3: 96,
  F4: 97,
  F5: 98,
  F6: 99,
  F7: 100,
  F8: 101,
  F9: 102,
  F10: 103,
  F11: 104,
  F12: 105,
  // "OEM1": 106,
  // "OEM2": 107,
  // "OEM3": 108,
  // "OEM4": 109,
  // "OEM5": 110,
  // "IMESelected": 111,
};
