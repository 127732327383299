import AgoraRTC from "agora-rtc-sdk-ng";
import store from "@/plugins/vuex/index";

export const getAgoraRTC = AgoraRTC;

export const MODE_RTC = "rtc"; // 通信场景
export const MODE_LIVE = "live"; // 直播场景
export const ROLE_HOST = "host"; // 主播
export const ROLE_AUDIENCE = "audience"; // 观众

export const PRIVATE_CHAT_UID_PREFIX = 975; // 私聊UID前缀

export const getUserIdByPrivateUid = (v) => {
  return Number((v + "").replace(PRIVATE_CHAT_UID_PREFIX, ""));
};
export const getPrivateUidByUserId = (v) => {
  return Number(PRIVATE_CHAT_UID_PREFIX + "" + (v + ""));
};
// 加入频道
const join = async ({ appId, channel, mode, uid, callback }) => {
  const rtc = {
    appId: appId,
    channel: channel,
    localAudioTrack: undefined, // 本地音频流
    localVideoTrack: undefined, // 本地视频流
    client: undefined, // 实例
    uid: -1, // 自动生成
  };
  console.log({ appId, channel, mode });
  rtc.client = AgoraRTC.createClient({ mode: mode, codec: "vp8" });
  // live模式必须先设置角色再join
  mode == MODE_LIVE && rtc.client.setClientRole(ROLE_HOST);
  if (uid) {
    await rtc.client.join(appId, channel, null, uid);
    rtc.uid = uid;
  } else {
    rtc.uid = await rtc.client.join(appId, channel, null);
  }
  callback && callback(rtc);
};

// 加入通信频道
export const joinRtc = ({ appId, channel, callback, uid }) => {
  join({
    appId,
    uid,
    channel,
    mode: MODE_RTC,
    callback: (rtc) => {
      store.dispatch("saveAgoraRtc", rtc);
      callback && callback(rtc);
    },
  });
};

// 加入直播频道
export const joinLive = ({ appId, channel, callback, uid }) => {
  join({
    appId,
    uid,
    channel,
    mode: MODE_LIVE,
    callback: (rtc) => {
      store.dispatch("saveAgoraLive", rtc);
      callback && callback(rtc);
    },
  });
};

// 加入直播频道 - more 给更多屏幕投屏
export const joinLiveMore = ({ appId, channel, uid, callback, notCreate }) => {
  join({
    appId,
    channel,
    uid,
    mode: MODE_LIVE,
    callback: (rtc) => {
      !notCreate && store.dispatch("addAgoraLiveMoreList", [rtc]);
      callback && callback(rtc);
    },
  });
};

// 切换直播角色
const setLiveClientRole = (role) => {
  store.getters.agoraLive.client &&
    store.getters.agoraLive.client.setClientRole(role);
};

// 切换直播角色 - 主播
export const setLiveClientRoleHost = () => {
  setLiveClientRole(ROLE_HOST);
};

// 切换直播角色 - 观众
export const setLiveClientRoleAudience = () => {
  setLiveClientRole(ROLE_AUDIENCE);
};
