import Vue from "vue";
import VueRouter from "vue-router";
import * as http from "../assets/js/http";
import store from "../plugins/vuex/index";
import { isMobile } from "../assets/js/utils";

Vue.use(VueRouter);
export const ROUTES_MODULE_FIRST_NAME = "stage";
export const routesConvert = ({ path, name, component, ...remain }, isRoot) => {
  return {
    path: `${isRoot ? `/${ROUTES_MODULE_FIRST_NAME}` : ""}${path}`,
    name: `${isRoot ? ROUTES_MODULE_FIRST_NAME : ""}${
      isRoot ? name.charAt(0).toUpperCase() + name.slice(1) : name
    }`,
    component: () => import(`@/views${component}`),
    ...remain,
  };
};
export const routesData = {
  index: {
    path: "/",
    name: "Index",
    component: "/Index.vue",
    meta: {
      title: "首页",
    },
  },
};
export const routesModuleData = {
  manage: {
    path: "/manage",
    name: "Manage",
    component: "/Manage.vue",
    meta: {
      title: "活动资源管理",
    },
  },
  statistics: {
    path: "/statistics",
    name: "Statistics",
    component: "/Statistics.vue",
    meta: {
      title: "活动统计",
    },
  },
  activityMy: {
    path: "/activity/my",
    name: "ActivityMy",
    component: "/activity/My.vue",
    meta: {
      title: "我的活动",
    },
  },
  activityCreate: {
    path: "/activity/create",
    name: "ActivityCreate",
    component: "/activity/Create.vue",
    meta: {
      title: "创建活动",
    },
  },
  activityFeilong: {
    path: "/activity/feilong",
    name: "ActivityFeilong",
    component: "/activity/Feilong.vue",
    meta: {
      title: "飞龙车模",
    },
  },
  activityFeilongSuccess: {
    path: "/activity/feilongsuccess",
    name: "ActivityFeilongSuccess",
    component: "/activity/FeilongSuccess.vue",
    meta: {
      title: "飞龙车模",
    },
  },
  detail: {
    path: "/detail",
    name: "Detail",
    component: "/Detail.vue",
    meta: {
      title: "活动详情",
    },
  },
  game: {
    path: "/game",
    name: "Game",
    component: "/Game.vue",
    meta: {
      title: "进入游戏",
    },
  },
};
const objectConvertToArray = (obj, isRoot) => {
  const arr = [];
  Object.keys(obj).forEach((key) => arr.push(routesConvert(obj[key], isRoot)));
  return arr;
};
const routes = [
  {
    ...routesConvert(routesData.index),
    children: [...objectConvertToArray(routesModuleData)],
  },
  ...objectConvertToArray(routesModuleData, true),
  ...[
    {
      path: "/stage/channel/:type",
      name: "ChannelCreate",
      component: () => import("@/views/channel/Create.vue"),
      meta: {
        title: "创建频道",
      },
    },
    {
      path: "/channel/view",
      name: "channelView",
      component: () => import("@/views/channel/view.vue"),
      meta: {
        title: "频道详情",
      },
    },
    {
      path: "/channel/data",
      name: "channelData",
      component: () => import("@/views/channel/dataCollect.vue"),
      meta: {
        title: "频道数据统计",
      },
    },
    {
      path: "/test",
      name: "test",
      component: () => import("@/components/takeOut/index.vue"),
      meta: {
        title: "频道数据统计",
      },
    },
  ],
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_SUB_PATH,
  routes,
});
router.beforeEach(async (to, from, next) => {
  // 埋点
  let title = to.meta.title;
  if (to.name == "stageManage") {
    title = "活动管理";
    if (to.query.tab == "activity") {
      title = "活动管理";
    } else if (to.query.tab == "resource") {
      title = "资源管理";
    }
  }
  setTimeout(() => {
    let terminal = "";
    if (isMobile().mobile) {
      terminal = isMobile().iPhone
        ? "iphone"
        : isMobile().android
        ? "android"
        : "mobile";
    } else {
      terminal = "pc";
    }
    http.post("/common/statistic/add", {
      user_id: store.getters.userInfo?.user_id || "",
      field_title: title,
      terminal: terminal,
    });
  }, 300);
  next();
});

export default router;
