// 获取私聊聊天记录
const messages = (state) => (user_id) => {
  const arr = [];
  state.messages.map((i) => {
    if (
      (i.from_user && i.from_user.user_id && i.from_user.user_id == user_id) ||
      (i.to_user && i.to_user.user_id && i.to_user.user_id == user_id)
    ) {
      arr.push(i);
    }
  });
  return arr;
};

// 获取公共聊天记录
const messagesAll = (state) => state.messagesAll;

// 获取用户信息
const userInfo = (state) => state.userInfo;

// 获取服务器配置
const config = (state) => state.config;

// 获取活动详情
const detail = (state) => state.detail;

// 获取文字聊天左侧用户列表
const textChatUserList = (state) => state.textChatUserList;

// 获取发言申请用户列表
const talkAuthApplyList = (state) => state.talkAuthApplyList;

// 获取通信对象
const agoraRtc = (state) => state.agoraRtc;

// 获取直播对象
const agoraLive = (state) => state.agoraLive;

// 获取直播对象组-给更多屏幕
const agoraLiveMoreList = (state) => state.agoraLiveMoreList;

// 获取直播对象-屏幕id绑定组
const agoraLiveMoreScreenBindList = (state) =>
  state.agoraLiveMoreScreenBindList;

// 获取视频聊天用户列表
const videoChatUserList = (state) => state.videoChatUserList;

// 获取正在投屏列表
const doingVideoProjectList = (state) => state.doingVideoProjectList;

// 获取推流监听-共享屏幕列表
const agoraLiveUserPublishedListenerMoreScreenBindList = (state) =>
  state.agoraLiveUserPublishedListenerMoreScreenBindList;

// 获取画布视频dom列表
const prepareList = (state) => state.prepareList;

// 获取直播监听
const agoraLiveListener = (state) => state.agoraLiveListener;

// 获取画布定时器集合
const canvasTimers = (state) => state.canvasTimers;

// 获取声音控制
const soundControl = (state) => state.soundControl;

// 在线用户列表
const onlineUserList = (state) => state.onlineUserList;

// 客服二维码
const csQrcode = (state) => state.csQrcode;

// 授权发言来源记录列表
const authAudioMark = (state) => state.authAudioMark;

export default {
  messages,
  messagesAll,
  userInfo,
  config,
  detail,
  textChatUserList,
  talkAuthApplyList,
  agoraRtc,
  agoraLive,
  agoraLiveMoreList,
  agoraLiveMoreScreenBindList,
  videoChatUserList,
  doingVideoProjectList,
  agoraLiveUserPublishedListenerMoreScreenBindList,
  prepareList,
  agoraLiveListener,
  canvasTimers,
  soundControl,
  onlineUserList,
  csQrcode,
  authAudioMark,
};
