import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./plugins/vuex/index";
import "./plugins/clipboad";
import "./plugins/vant";
import "./plugins/vue-select";
import * as http from "./assets/js/http";
import * as storage from "./assets/js/storage";
import * as utils from "./assets/js/utils";
import * as agoraUtils from "./assets/js/agoraUtils";
import * as webrtc from "./assets/js/webrtc/main";
import { Loading } from "element-ui";
import "@/assets/css/base.css";
import "@/assets/css/theme.scss";

Vue.config.ignoredElements = ["wx-open-launch-weapp"];
Vue.config.productionTip = false;
Vue.prototype.$ossRes = (v) => {
  return process.env.VUE_APP_RESOURCE_URL + v;
};
Vue.prototype.$u3dLauncher = {};
Vue.prototype.$u3dConnection = {};
Vue.prototype.$u3dLivePlayer = {};
Vue.prototype.$http = http;
Vue.prototype.$storage = storage;
Vue.prototype.$utils = utils;
Vue.prototype.$agoraUtils = agoraUtils;
Vue.prototype.$webrtc = webrtc;
Vue.prototype.$webrtcPlayer = {};

Vue.use(Loading);
// const worker = new Worker("/worker.js");
// Vue.prototype.$logger = (s) => {
//   worker.postMessage({
//     token: localStorage.getItem("token"),
//     data: s,
//   });
// };

new Vue({
  router,
  render: (h) => h(App),
  store,
  // i18n,
}).$mount("#app");
